// Images BG
export const BG_AYAT = `https://ik.imagekit.io/zaanfa/surya-chandra/ayat_sTvi1IbV4.jpg?updatedAt=1695884003406`;
export const BG_COVER = `https://ik.imagekit.io/zaanfa/surya-chandra/cover_3Pa3KkeVB6.jpg?updatedAt=1695884003389`;
export const BG_PATTERN = `https://user-images.githubusercontent.com/10141928/147431083-48106e7a-9aed-4108-8ef6-8a666bd48b5a.jpeg`;
export const BG_WEDDING_DETAIL = `https://ik.imagekit.io/zaanfa/surya-chandra/wedding_n2N7KlaIje.jpg?updatedAt=1695884003401`;
export const BG_WELCOME = `https://ik.imagekit.io/zaanfa/surya-chandra/welcome_W4tsiL2Qt.jpg?updatedAt=1695884003381`;
export const BG_WISHLIST = `https://ik.imagekit.io/zaanfa/surya-chandra/wishes_IZ_DGoOlV.jpg?updatedAt=1695884008213`;
export const BG_YOUTUBE = `https://ik.imagekit.io/zaanfa/surya-chandra/youtube_ubx60I74yT.jpg?updatedAt=1695884003607`;
export const BG_START_JOURNEY = BG_COVER;
export const BG_LOVESTORY = BG_COVER;
export const BG_FILTER = `https://ik.imagekit.io/zaanfa/surya-chandra/filter_4kM-5Fv6u.jpg?updatedAt=1696837428098`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/zaanfa/surya-chandra/dekstop_94UbRKN9_r.jpg?updatedAt=1695884003401`;

// The Bride Photos
export const IMG_MAN = `https://ik.imagekit.io/zaanfa/surya-chandra/Groom_Z2vM-0qWq.jpg?updatedAt=1695884003294`;
export const IMG_GIRL = `https://ik.imagekit.io/zaanfa/surya-chandra/girl_W_2VL4d2Lq.jpg?updatedAt=1695884003299`;

// COver Gallery & YouTube Thumbnail
export const COVER_YOUTUBE = `https://lh3.googleusercontent.com/-PzMzRP-ufY0/YZ-BUy08YNI/AAAAAAAAABQ/FrptBB-lxVkmzxl3GeIS5eBONvxZcVxwwCLcBGAsYHQ/flower.jpg`;
export const COVER_GALLERY_1 = `https://ik.imagekit.io/zaanfa/surya-chandra/gallery_bcWOSvF5m.jpg?updatedAt=1695884003394`;
// fill this constants below if you need more 1 cover gallery
export const COVER_GALLERY_2 = ``;
export const COVER_GALLERY_3 = ``;

// Invitato Logo
export const INVITATO_LOGO = `https://user-images.githubusercontent.com/10141928/137603877-5d2ddf10-39a7-4586-8ab1-d9cca7fe8898.png`;

// DON'T CHANGE WITH CDN IMAGE
import ImgQRCover from '../images/qrcover.jpg'
export const IMG_QR_COVER = ImgQRCover;

// Collaboration Vendor Logos
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_TIRTOWENING = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_prahasta = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS = 'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';